import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { DefaultCard } from '@ui/cards/default-card/DefaultCard';

import { SubscriptionPackageContent } from './SubscriptionPackageContent';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import {
  isProductNotExpired,
  isStatusActiveOrPending,
} from '@templates/common/helpers';

export const SubscriptionPackage: FC = () => {
  const { t } = useTranslation();
  const {
    selectedSubscription,
    isLoading: isSubscriptionsLoading,
    isFetching: isSubscriptionsFetching,
    isError: isSubscriptionsError,
  } = useSubscriptions();
  const subscriptionPackage = isStatusActiveOrPending(
    selectedSubscription?.status,
  )
    ? selectedSubscription?.products.find(
        (product) =>
          isProductNotExpired(product) &&
          isStatusActiveOrPending(product.status),
      )
    : undefined;
  const status =
    selectedSubscription?.status === 'PENDING'
      ? t('home:auth.subscription.statuses.PENDING')
      : '';

  return (
    <DefaultCard
      title={t('home:auth.package.title')}
      titleNote={status}
      titleNoteColor="warning.light"
      noBorder={false}
    >
      <SubscriptionPackageContent
        isSubscriptionsFetching={isSubscriptionsFetching}
        isSubscriptionsLoading={isSubscriptionsLoading}
        isSubscriptionsError={isSubscriptionsError}
        subscriptionPackage={subscriptionPackage}
      />
    </DefaultCard>
  );
};
