import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@lib/route/constants';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { LinkButton } from '@ui/buttons/LinkButton';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';

import {
  isProductNotExpired,
  productActivatesInFuture,
  isStatusActiveOrPending,
} from '../../../common/helpers';
import { SubscriptionAddonsContent } from './SubscriptionAddonsContent';

export const SubscriptionAddons: FC = () => {
  const { t } = useTranslation();
  const { selectedSubscription } = useSubscriptions();
  const parentProduct = selectedSubscription?.products.find(
    (product) =>
      isProductNotExpired(product) && isStatusActiveOrPending(product.status),
  );
  const addonProducts = isStatusActiveOrPending(selectedSubscription?.status)
    ? parentProduct?.children?.filter(
        (product) =>
          isProductNotExpired(product) &&
          isStatusActiveOrPending(product.status),
      )
    : undefined;

  const showManageAddon =
    selectedSubscription?.status === 'ACTIVE' &&
    parentProduct?.status === 'ACTIVE';

  const allowManageAddon = addonProducts?.every(
    (product) =>
      product.status === 'ACTIVE' ||
      (product.status === 'PENDING' && productActivatesInFuture(product)),
  );

  return (
    <DefaultCard
      title={t('home:auth.addons.title')}
      sx={styles.getValue('cardContainer')}
      noBorder={false}
      contentSx={styles.getValue('cardContent')}
      button={
        showManageAddon ? (
          <LinkButton
            href={APP_ROUTES.PROFILE.SPECIAL_OFFERS.path}
            variant="contained"
            disabled={!allowManageAddon}
          >
            {t('home:auth.addons.buttons.addNew')}
          </LinkButton>
        ) : undefined
      }
    >
      <SubscriptionAddonsContent products={addonProducts} />
    </DefaultCard>
  );
};

const styles = new SxStyles({
  cardContainer: {
    maxHeight: {
      md: '600px',
    },
  },
  cardContent: {
    overflowY: {
      md: 'auto',
    },
  },
  action: {
    color: 'light.main',
  },
});
