import { format, formatISO, parseISO } from 'date-fns';
import { getDateFnsLocale } from '@lib/time/locale';
import { DEFAULT_LOCALE } from '@lib/route/constants';

export const getDateFormatFromLocale = (
  nextLocale: string = DEFAULT_LOCALE,
  dateFormatType:
    | 'Date'
    | 'DayMonth'
    | 'DateWithTime'
    | 'DateWithPreciseTime' = 'Date',
): string => {
  const dateFnsLocale = getDateFnsLocale(nextLocale);

  const dateFnsFormat: string = dateFnsLocale.formatLong?.date({
    width: 'short',
  });
  const yearIndex = dateFnsFormat.indexOf('y');
  const dateFormat = dateFnsFormat.replaceAll('y', '');

  switch (dateFormatType) {
    case 'Date': {
      if (yearIndex) {
        return dateFormat + 'yyyy';
      } else {
        return 'yyyy' + dateFormat;
      }
    }
    case 'DayMonth': {
      return yearIndex ? dateFormat.slice(0, yearIndex - 1) : dateFormat;
    }
    case 'DateWithTime': {
      const timeFnsFormat: string = dateFnsLocale.formatLong?.time({
        width: 'short',
      });
      if (yearIndex) {
        return `${dateFormat}yyyy ${timeFnsFormat}`;
      } else {
        return `yyyy${dateFormat} ${timeFnsFormat}`;
      }
    }
    case 'DateWithPreciseTime': {
      const timeFnsFormat: string = dateFnsLocale.formatLong?.time({
        width: 'medium',
      });
      if (yearIndex) {
        return `${dateFormat}yyyy ${timeFnsFormat}`;
      } else {
        return `yyyy${dateFormat} ${timeFnsFormat}`;
      }
    }
  }
};

export const normalizeDate = (date: Date): Date =>
  new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));

export const formatDate = (
  date: Date | string,
  dateFormat:
    | 'Date'
    | 'DayMonth'
    | 'DateWithTime'
    | 'DateWithPreciseTime' = 'Date',
  nextLocale: string = DEFAULT_LOCALE,
): string => {
  try {
    const value = date instanceof Date ? date.toISOString() : date;
    const localeFormat = getDateFormatFromLocale(nextLocale, dateFormat);

    return typeof value === 'string'
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        format(normalizeDate(parseISO(value))!, localeFormat)
      : '';
  } catch (e) {
    return '';
  }
};

export const getAge = (dateString: Date): number => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const TODAY_ISO_DATE = formatISO(new Date(), { representation: 'date' });
